@import '@matillion/component-library/dist/assets/styles/colours';

.Panel {
  &__SideBar {
    min-width: 250px;
  }
}

.ApplicationContent {
  width: 100vw;
  height: 100%;
}

.CanvasContainer {
  height: 100%;
  overflow: auto;
}
