@use '@matillion/component-library/dist/assets/styles/fonts' as typography;
@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.NameWrapper {
  // needed so that we have a positional parent
  // to absolutely position the validity message correctly
  position: relative;
  height: 32px;
  border-bottom: 1px solid colours.$cloudy;

  &__NameInput {
    @include typography.bcs;
    position: relative;
    z-index: 1;
    padding-left: 16px;
    line-height: 14px;
  }
}

.ValidityMessage {
  display: flex;
  position: absolute;
  z-index: 111;
  top: 32px;
  right: 0;
  left: 0;
  padding: 8px;
  border-bottom: 1px solid colours.$cloudy;
  background-color: colours.$white;
  color: colours.$fire-red;
  gap: 8px;
}

.SideBar {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__Child {
    flex: 1;
    max-height: 100%;
    overflow: auto;
  }
}

.CodeMirrorCodeEditor {
  flex: 1;
  border: 0 !important;
}

.EditorWrapper {
  // stop content from expanding the cell
  min-width: 0;
  height: 100%;
  min-height: 0;
  overflow: auto;
}

.ErrorAlert {
  margin-bottom: 24px;
}
