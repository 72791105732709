@import '@matillion/component-library/dist/assets/styles/colours';

$error-icon: url('../../../../assets/failed-small.svg');
$warning-icon: url('../../../../assets/warning-small.svg');

.ValueRenderer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  input,
  li {
    font-size: 14px;
  }

  &__InputContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    white-space: nowrap;
  }

  &__Value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__ErrorPanel {
    margin-top: 4px;
    margin-bottom: 16px;
    padding-left: 18px;
    color: $fire-red;
    text-indent: -18px;
  }
}
