@use 'styles/_designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

$margin-button: 4px;

.PipelineBrowser {
  display: flex;
  flex-direction: column;
  height: calc(100% - tokens.$section-header-height);

  &__Header {
    display: flex;
    padding: 8px 16px;
  }

  &__Loading {
    margin: auto;
  }

  &__Title {
    // optically aligns the Telegraf text better with the buttons
    transform: translateY(1px);
  }

  &__CTAContainer {
    display: flex;
    flex-grow: 20;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    margin-right: -8px;
  }

  &__CTAPopoverMenu {
    width: 220px;
  }

  &__CTA {
    display: inline-block;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $black;
    cursor: pointer;

    svg {
      vertical-align: top;
    }

    &:focus::after {
      display: none;
    }

    &:not([disabled]):hover {
      border-radius: 50%;
      background-color: $windy;
    }
  }

  &__ListWrapper {
    height: 100%;
  }

  &__Content {
    height: 100%;
    overflow: auto;
  }
}

.EmptyPipelineBrowserList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 24px;

  &__Text {
    margin-top: 8px;
    color: $hurricane;
    text-align: center;
  }

  &__CTAContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 48px;
    gap: 16px;
  }

  &__Button {
    height: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    &:not([disabled]):hover {
      color: $black;
    }

    &:focus::after {
      display: none;
    }

    &__Content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__Text {
      color: $black;
    }

    svg {
      min-width: 24px;
      margin-right: 6px;
    }
  }
}

.MenuPopover {
  z-index: 100;
}

.SidebarMenuItem {
  padding-right: 3rem;
  padding-left: 2.6rem;
  border-left: 0.4rem solid transparent;

  &:hover {
    background-color: $misty;
    color: $electric-green;
  }
}
