@use '@matillion/component-library/dist/assets/styles/colours' as *;
@use 'styles/designTokens' as tokens;

$active-tab-color: #6c6c6c;
$close-tab-button-color: #444;

.InteractiveTabs__TabList {
  display: flex;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $cloudy;
  background: #f9f9f9;
}

.InteractiveTabs__TabButton {
  z-index: 1;
  padding-top: 0;
  padding-right: 4px;
  padding-bottom: 0;
  padding-left: 8px;
  color: $active-tab-color;
  gap: 0;

  &:focus {
    &::after {
      border: 0;
    }
  }

  &:not(:disabled):hover {
    background: none;
    color: $black;
    text-decoration: none;
  }
}

.InteractiveTabs__TabIconBefore {
  color: $hurricane;
}

.InteractiveTabs__TabIconBefore,
.InteractiveTabs__TabCloseButton {
  margin-right: 6px;
}

.InteractiveTabs__TabTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.InteractiveTabs__Scrollable {
  position: relative;
  min-height: tokens.$jobs-tab-height; // 40px is the height of the tab title + padding
}

.InteractiveTabs__ScrollArea {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  overflow-x: auto;
  transition: visibility 0.2s;
}

.InteractiveTabs__ScrollArea:hover,
.InteractiveTabs__TabList {
  visibility: visible;
}

.InteractiveTabs__TabCloseButton {
  z-index: 1;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: $close-tab-button-color;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: $misty;
  }
}

.InteractiveTabs__PopOver {
  width: 200px;
}

.InteractiveTabs__TabContainer {
  display: flex;
  align-items: center;
  max-width: 215px;
  padding-top: 4px;
  padding-bottom: 4px;
  transition: all 0.2s linear;
  border-top: 2px solid transparent;
  border-right: 1px solid $cloudy;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  background-color: $misty;
  cursor: pointer;

  &--active {
    outline: none;
    background-color: $white;
    color: $black;
  }

  &:hover {
    background-color: $white;

    button {
      color: $black;
    }
  }

  &:focus-within {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: -2px;
      right: 0;
      bottom: -1px;
      left: 0;
      border: 1px solid $black;
    }
  }
}

.InteractiveTabs--stretch {
  &__TabButton {
    flex: 1;
  }
}
