@use '@matillion/component-library/dist/assets/styles/colours' as *;

.RowLoading {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  &__Icon,
  &__Text {
    color: $hurricane;
  }

  &__Icon {
    width: 3rem;
    margin-right: 1rem;
  }
}
