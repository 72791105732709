@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.Collapsible {
  display: flex;
  // we need pixel values for heights, for the overflow to be controlled in flex children
  min-height: 600px;
  max-height: 800px;
  border: 1px solid colours.$cloudy;

  &__CollapsibleSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 32px;

    &--isCollapsed {
      flex: 0;
    }

    &__ActionBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      padding: 0 16px;
      border-bottom: 1px solid colours.$cloudy;

      &--isCollapsed {
        padding: 0 10px;
        border-bottom: 0;
      }

      &__ActionButton {
        min-width: 14px;
        height: 14px;
      }
    }

    &__Content {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-height: 100%;
      overflow: auto;
    }
  }

  &__Content {
    display: flex;
    flex: 2;
    flex-direction: column;
    min-width: 0;
    border-left: 1px solid colours.$cloudy;
  }
}
