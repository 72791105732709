@import '@matillion/component-library/dist/assets/styles/colours';

.Dropdown {
  width: 100%;
  height: 32px;
  padding-left: 3px;
  border: 2px solid transparent;
  text-overflow: ellipsis;

  &:focus,
  &:hover,
  &:hover:not([disabled]) {
    border: 2px solid $black;
    background-color: $white;
    box-shadow: none;
  }
}

.Dropdown__Container {
  width: 100%;

  &--isLoading {
    height: 32px;
  }

  &--Error {
    border: 2px solid $fire-red;
  }
}
