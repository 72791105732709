@import '@matillion/component-library/dist/assets/styles/colours';

.NameRenderer {
  &__Label {
    display: flex;
    width: 100%;
    pointer-events: none;
  }

  &__Name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__Optional {
    flex: 1;
    padding-left: 4px;
    color: $hurricane;
    text-align: start;
    white-space: nowrap;
  }

  &__Tooltip {
    display: flex;
    padding: 1rem 2rem;
  }
}
