@import '@matillion/component-library/dist/assets/styles/colours';

.EmptyPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  margin: 25px 1.5em;
  color: $hurricane;
  text-align: center;

  &__Icon {
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
    color: $hurricane;
  }
}
