.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__Icon {
    margin-bottom: 8px;
  }

  &__Text {
    text-align: center;
  }
}
