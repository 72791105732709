@import '@matillion/component-library/dist/assets/styles/colours';

.Button {
  width: 85px;
  height: 30px;
  padding: 5px;
}

@mixin chevron {
  width: 12px;
  margin-left: 8px;

  path {
    stroke-width: 2px;
    stroke: $cloudy;
  }
}

.ChevronUp {
  @include chevron;
  rotate: 180deg;
}

.ChevronDown {
  @include chevron;
}

.Info {
  position: absolute;
  z-index: 2;
  top: -5px;
  left: -5px;
}

.MenuItem {
  margin: 0;
  padding: 0;
}
