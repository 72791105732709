@use '@matillion/component-library/dist/assets/styles/colours' as *;

.RowEmpty {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  &__Icon,
  &__Text {
    color: $hurricane;
  }

  &__Icon {
    width: 1.5rem;
    margin-right: 1rem;
  }
}
