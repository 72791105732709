@import '@matillion/component-library/dist/assets/styles/colours';

.ItemSelect {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0;

  &__Column {
    width: 320px;

    @media screen and (max-width: 1200px) {
      width: 276px;
    }
  }

  &__TableList {
    height: 355px;
    border: 1px solid $cloudy;
    border-top: 0;

    * {
      user-select: none;
    }
  }

  &__ListHeader {
    margin-bottom: 8px;
    font-weight: 700;
  }
}
