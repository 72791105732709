@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';

.ListItem {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 6px 8px;
  overflow: hidden;
  border-right: 1px solid $cloudy;
  border-bottom: 1px solid $cloudy;
  background: $misty;

  p {
    @include bcs();
    flex: 1;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--dragging {
    border: 2px solid $electric-green;
    background: $white;
    color: $electric-green;
  }

  &--selected {
    border: 1px solid $electric-green;
    background: $white;
    color: $electric-green;
  }

  &--ghosting {
    border: 1px solid $cloudy;
    background: $white;
    color: $cloudy;
  }

  &__SelectionCount {
    position: absolute;
    z-index: 10;
    top: -19px;
    right: -19px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: $electric-green;
    color: $white;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
  }

  &__CollapseIcon {
    transform: rotate(180deg);
  }

  &--groupHeader {
    background: $white;
  }

  &__IconClickArea {
    padding: 8px;
    cursor: pointer;
  }

  &__Item {
    display: flex;
    align-items: center;
  }

  &--Invalid {
    border: 1px solid $fire-red;
  }
}

.Cross {
  padding-left: 8px;

  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;

    circle {
      fill: $fire-red;
    }

    path {
      fill: $white;
    }
  }
}
