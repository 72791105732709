@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.Shortcuts {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid colours.$cloudy;
  background-color: colours.$misty;

  &__Button {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 14px;
    border: 0;
    border-right: 1px solid colours.$cloudy;
    background-color: colours.$misty;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: colours.$white;
    }

    &--Disabled {
      color: colours.$windy;
    }
  }
}
