.ZoomToolbar {
  position: relative;
}

.ZoomToolbar__Percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5em;
  height: 44px;
}
