@import '@matillion/component-library/dist/assets/styles/colours';

.HeaderBar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 45px;
  border-bottom: 1px solid $cloudy;

  &__InlineButton:not(:last-child) {
    padding-right: 1rem;
  }
}
