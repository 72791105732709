@import '@matillion/component-library/dist/assets/styles/colours';

.RadioCard {
  &__RadioContainer {
    margin-left: auto;
    padding: 16px 16px 0 0;
  }

  &__PositiveTag {
    padding: 8px 16px;
    border-radius: 0 0 15px;
    background-color: $soft-positive;
    color: $dark-positive;
  }
}
