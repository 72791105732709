@import '@matillion/component-library/dist/assets/styles/colours';
$enter-icon: url('../../assets/enter-small.svg');
$pen-icon: url('../../assets/edit-small.svg');

@mixin get-background-icon($icon) {
  background: $icon no-repeat calc(100% - 14px) 6px;
  background-size: 18px;
}

.TextInput {
  width: 100%;
  height: 32px;
  border: 1px solid transparent;

  &__ReadOnly,
  &__Editable {
    padding-right: 40px;
    background-size: 20px;
  }

  &__ReadOnly {
    @include get-background-icon($pen-icon);
  }

  &__Editable {
    @include get-background-icon($enter-icon);
  }

  &__Error {
    border-color: $fire-red;
  }

  &:focus:not([disabled]),
  &:hover:not([disabled]),
  &:focus:hover:not([disabled]) {
    border-color: $black;
    background-color: $white;
    box-shadow: 0 0 0 1px $black;
  }
}
