@import '@matillion/component-library/dist/assets/styles/colours';

.ProjectToolbar {
  border-right: $cloudy 1px solid;

  li {
    border-left: $cloudy 1px solid;

    p {
      padding-right: 1rem;
    }
  }

  &__JobIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }

  &__DropDown {
    display: flex;
    margin: 1px 0;
  }
}

.Selector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 252px;
  min-height: 45px;
  gap: 4px;

  &__TextWrapper {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }

  &__IconWrapper {
    margin-right: 4px;
    margin-left: 16px;
  }

  &__Error {
    color: $hurricane;
  }
}

.ProjectExplorer {
  &__LinkContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    white-space: nowrap;

    &:hover {
      .ProjectExplorer__IconWrapper {
        border-radius: 50%;

        circle {
          fill: $cloudy;
        }
      }
    }
  }

  &__ManageLink {
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: $black;
    gap: 4px;
  }

  &__IconWrapper {
    padding-top: 3px;

    svg {
      transform: rotate(180deg);

      circle {
        fill: transparent;
      }
    }
  }
}
