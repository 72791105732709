.Portal {
  position: fixed;
  bottom: 0;
  left: -100%;
}

.PortalContent {
  display: flex;
  justify-content: center;
  padding: 5px;

  .Text {
    margin-left: 5px;
  }
}
