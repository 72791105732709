@import '@matillion/component-library/dist/assets/styles/colours';

.HorizontalStack {
  display: flex;
  list-style: none;

  &--Spacer {
    > .HorizontalStack__Item + .HorizontalStack__Item {
      border-left: $cloudy 1px solid;
    }
  }
}
