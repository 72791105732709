@import '@matillion/component-library/dist/assets/styles/colours';

.Footer {
  display: flex;
  flex-direction: column;

  &__Divider {
    margin-bottom: 20px;
    color: $windy;
  }

  &__Warning {
    margin-bottom: 20px;
  }

  &__Buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 8px;
  }
}
