@use '@matillion/component-library/dist/assets/styles/colours' as colours;

$opaque-grey: rgba(colours.$black, 0.1);

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.ModalContainer {
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.AddNextComponentCTA {
  z-index: 5;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 9px;

  border: 0;
  background-color: $opaque-grey;
  color: inherit;
  font: inherit;
  text-align: left;
  cursor: pointer;
  appearance: none;
}

.Search {
  margin: 10px 0;

  &__Input {
    height: 34px;
  }
}

.ComponentsList {
  flex-grow: 1;
  overflow-y: auto;
  list-style-type: none;
}
