.ScheduleModal {
  &__Alert {
    margin-top: 20px;
  }

  &__Button {
    padding: 13px 30px;
  }

  &__Footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: -25px;
    padding-top: 32px;
  }
}
