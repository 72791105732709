@import '@matillion/component-library/dist/assets/styles/colours';

.FreeTextInputEditor__Input {
  width: calc(100% - 2px);
  margin-left: 1px;
  padding-left: 5px;
  text-overflow: ellipsis;

  &--Error {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px $fire-red;
  }
}
