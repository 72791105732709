@import '@matillion/component-library/dist/assets/styles/colours';

$note-colour: (
  'green': $electric-green,
  'yellow': $thunder-yellow,
  'red': $fire-red,
  'white': $white,
  'light-green': $gale,
  'light-yellow': $sandstorm,
  'light-red': $warm-breeze,
  'light-blue': $breeze
);
$note-background: (
  'green': $gale,
  'yellow': $sandstorm,
  'red': $warm-breeze,
  'white': $white,
  'light-green': $white,
  'light-yellow': $white,
  'light-red': $white,
  'light-blue': $white
);
$dots-colour: (
  'green': $white,
  'yellow': $windy,
  'red': $white,
  'white': $windy,
  'light-green': $windy,
  'light-yellow': $windy,
  'light-red': $windy,
  'light-blue': $windy
);
