@use '@matillion/component-library/dist/assets/styles/colours' as colours;
@use '../../styles/designTokens' as tokens;

$section-spacing: 4rem;
$action-spacing: 2rem;
$max-content-width: 55rem;
$icon-size: 3.5rem;

.ErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: tokens.$foggy;
  text-align: center;

  &--Overlay {
    position: absolute;
    z-index: 10;
    inset: 0; // same as top: 0; right: 0; bottom: 0; left: 0;
  }

  &__Container {
    margin: auto;
    padding: $section-spacing 0;
    text-align: center;
  }

  &__Icon,
  &__Title,
  &__Content,
  &__Actions {
    margin: $section-spacing auto;
  }

  &__Title,
  &__Content {
    max-width: $max-content-width;
  }

  &__Icon {
    svg {
      width: $icon-size;
      height: auto;
    }
  }

  &__Actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: $action-spacing;
  }
}
