.Stack {
  padding: 16px 0;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  &--centered {
    text-align: center;
  }
}
