@import '@matillion/component-library/dist/assets/styles/colours';

.NoContentMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  &__Icon {
    margin-bottom: 1rem;
    color: $black;
  }

  &__Text {
    color: $hurricane;
    text-align: center;
  }
}
