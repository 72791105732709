@import '@matillion/component-library/dist/assets/styles/colours';

.GettingStarted {
  padding: 48px 0;

  &__Title {
    margin-bottom: 1rem;
    text-align: center;
  }

  &__CTAContainer {
    display: flex;
    justify-content: flex-end;
  }

  &__CardsContainer {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    gap: 3rem;
  }
}
