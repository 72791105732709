@import '@matillion/component-library/dist/assets/styles/colours';

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Heading {
  padding: 7px 0 7px 16px;
  border-bottom: 1px solid $cloudy;
}

.List {
  flex: 1;
  max-height: 100%;
  overflow: auto;
  list-style: none;
}

.ItemArrow {
  visibility: hidden;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.Item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 16px;
  border-bottom: 1px solid $cloudy;

  &--Active {
    background-color: #f8f8f8;

    .ItemArrow {
      visibility: visible;
      color: $plasma-green;
    }
  }

  &:hover,
  &:focus {
    background-color: $misty;

    .ItemArrow {
      visibility: visible;
    }
  }
}

.NamePlaceholder {
  color: $windy;
}

.EmptyPlaceholder {
  height: 100%;
}

.Actions {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-top: 1px solid $cloudy;
  background-color: $misty;

  &__Button {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}
