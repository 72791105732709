@import '@matillion/component-library/dist/assets/styles/colours';

.MultiOptionSelector {
  &__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    overflow-y: scroll;
    border: 1px solid $cloudy;
  }

  &__Content {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }

  &__Value {
    flex: 1;
    padding-left: 6px;
    overflow: hidden;
    white-space: nowrap;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }

  &__ButtonContainer {
    display: flex;
    padding: 6px 20px;
    background-color: $misty;
  }

  &__AddRow {
    button {
      padding: 0;
    }
  }

  &__DeleteRow {
    button {
      padding: 0;
    }
  }
}
