.Controls {
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  padding-top: 72px;

  svg {
    margin-bottom: 8px;
  }
}
