.Folder {
  display: flex;
  align-items: center;
  width: 100%;

  &__Icon {
    width: 16px;
    height: 20px;
    margin-right: 6px;
  }
}

.FolderIcon {
  margin-left: -4px;
}

.Text {
  flex-grow: 5;
  align-items: center;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.TextContainer {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
