@import '@matillion/component-library/dist/assets/styles/colours';

.EmptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  color: $hurricane;
  gap: 12px;
}
