@import '@matillion/component-library/dist/assets/styles/colours';

.OAuthEditor {
  &__LinkContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 12px;
  }

  &__ManageLink {
    display: flex;
    align-items: flex-end;
    color: $plasma-green;
    gap: 4px;
  }
}
