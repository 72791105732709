@import '@matillion/component-library/dist/assets/styles/colours';

.ErrorPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto 0;
  padding: 0 24px;
  text-align: center;

  &__Icon {
    margin-bottom: 8px;
    color: $hurricane;
  }

  &__Text {
    color: $hurricane;
  }

  &__Link {
    margin-left: 8px;
    color: $black;

    &:hover {
      text-decoration: underline;
    }

    &:not([disabled]):hover {
      color: $black;
    }
  }
}
