@import '@matillion/component-library/dist/assets/styles/colours';

.ExternalLink {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  a {
    display: flex;
    align-items: baseline;
    color: $black;

    &:hover {
      color: $electric-green;
      text-decoration: underline;
    }
  }

  p {
    margin-right: 5px;
    font-size: 14px;
    line-height: 18px;
  }

  svg {
    width: 10px;
    height: 10px;
  }
}
