@import '@matillion/component-library/dist/assets/styles/colours';

.Wrapper {
  display: flex;
  align-items: center;
  padding: 4px 0;
  pointer-events: none;
}

.Icon {
  width: 20px;
  margin-left: 15px;
}

.ProjectName {
  margin-left: 5px;
}

.Active {
  background-color: $misty;
}
