@use '@matillion/component-library/dist/assets/styles/colours' as *;

$orchestration-job-color: $ocean-blue;
$transformation-job-color: #19e57f;
$task-details-job-color: $black;

.JobTabs__Tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.JobTabs__TabContent {
  height: 100%;
}

.JobTabs__Tab--ActiveTab,
.JobTabs__Tab:hover {
  border-bottom: 2px solid $transformation-job-color;
}

.JobTabs__Tab--Orchestration {
  &.JobTabs__Tab--ActiveTab,
  &:hover {
    border-bottom: 2px solid $orchestration-job-color;
  }
}

.JobTabs__Tab--TaskDetails {
  &.JobTabs__Tab--ActiveTab,
  &:hover {
    border-bottom: 2px solid $task-details-job-color;
  }
}
