@import '@matillion/component-library/dist/assets/styles/colours';

@mixin highlight {
  border-color: $positive;
  box-shadow: 0 0 0 1px $positive;
}

.SelectableCard {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  border: 1px solid $grey-01;
  border-radius: 16px;
  background-color: $grey-03;
  cursor: pointer;

  &:hover,
  &--selected {
    @include highlight;
  }
}
