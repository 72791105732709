@import 'src/modules/SideBar/components/ComponentBrowser/ComponentBrowser.module';

.SchemaTooltip {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__Text {
    flex: 1;
    align-items: center;
    min-width: 0;
    overflow: hidden;
    line-height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ContextButton {
  display: inline-block;
  margin-right: 1rem;
  padding: 0;

  .ButtonIcon {
    width: 16px;
    padding: 0;
    //@hack this overrides a default behavior from the TreeItem component
    circle {
      fill: #000 !important;
    }
  }
}
