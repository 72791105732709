/* these are utility/override classnames, and need to
 * to be imported after all component modules to have
 * a higher css specificity */

%u-visually-hidden-placeholder {
  position: absolute !important;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.u-visually-hidden {
  @extend %u-visually-hidden-placeholder;
}

.u-truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-2-lines {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-line-clamp: 2;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }
}
