@import '@matillion/component-library/dist/assets/styles/colours';

.EtlNoteToolbar {
  &__Button {
    position: static;
    padding: 7px;

    &:hover {
      background: $misty;
    }
  }

  &__Tooltip {
    margin: 10px;
    padding: 10px;
  }
}
