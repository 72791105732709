@use '@matillion/component-library/dist/assets/styles/colours' as colours;

$opaque-grey: rgba(colours.$black, 0.1);

.FlowNodeWrapper {
  position: relative;
  z-index: 1;

  &:has([class*='isSelected']) {
    border-radius: 4px;
    box-shadow: 0 0 0 8px $opaque-grey;
  }

  &__Container {
    position: relative;
  }

  &__Handle {
    background: none;

    &--input {
      left: 0;
    }

    &--output {
      right: 0;
    }
  }

  &__FullInput {
    position: absolute;
    // arbitrary value to make the handle appear slightly bigger than the component icon
    inset: -10px;

    &__Handle {
      position: absolute;
      transform: none;
      background-color: transparent;
      inset: 0;
    }
  }

  &__Ports {
    display: flex;
    position: absolute;
    top: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    min-height: 100%;
    transform: translateY(-50%);

    &--hidden {
      opacity: 0;
    }

    &:hover {
      z-index: 2;
    }
  }

  &__InputPorts {
    right: 100%;
    padding-right: 7px;
  }

  &__OutputPorts {
    left: 100%;
    padding-left: 7px;
  }

  &__IteratorPorts {
    bottom: 0;
    flex-direction: row;
    width: 100%;
    min-height: 0;
    transform: translateY(100%);
  }

  &__AddNextOffset {
    position: absolute;
    top: 0;
    right: -130px;
  }
}

.FlowPort {
  /* these are needed to override react-flow's default handle styles */
  position: static;

  padding: 2px 0;
  transform: none;
  background-color: transparent;

  &__Icon {
    display: block;
    width: 13px;
    height: 13px;
    stroke-width: 3.5px;
    fill: colours.$misty;
    pointer-events: none;
  }

  &--SUCCESS {
    color: colours.$plasma-green;
  }

  &--UNCONDITIONAL,
  &--ITERATION,
  &--INPUT {
    color: colours.$hurricane;
  }

  &--ITERATION,
  &--TRUE {
    color: colours.$ocean-blue;
  }

  &--FALSE {
    color: colours.$lava-orange;
  }

  &--FAILURE {
    color: colours.$fire-red;
  }
}

.FlowPort--active {
  transform: scale(1.5);

  .FlowPort__Icon {
    stroke-width: 2.5px;
  }
}

.FlowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
