.ComponentName {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &__Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
