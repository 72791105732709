@import '@matillion/component-library/dist/assets/styles/colours';

.FooterContainer {
  border: 1px solid $cloudy;
  border-top: 0;
}

.GridContainer {
  height: 400px;
}

.Alert {
  margin-bottom: 24px;
}
