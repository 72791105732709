@import '@matillion/component-library/dist/assets/styles/colours';

.Tooltip {
  &__Container {
    padding: 1rem 2rem;
  }

  &__IconButton {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 44px;
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;

    &__FixedSize {
      width: 44px;
    }

    &:focus {
      border: 1px solid $black;
    }
  }

  &__IconButton[disabled] {
    cursor: default;
  }
}

.Shortcut_Keys {
  padding: 0;
  color: $hurricane;
  text-transform: uppercase;
  user-select: none;
}

.MacOs {
  font-family: Telegraf, -apple-system, Arial, Helvetica, sans-serif;
}
