@import '@matillion/component-library/dist/assets/styles/colours';

.Stroke {
  position: absolute;
  top: -6px;
  left: -7px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $ocean-blue;
}

.Refresh {
  position: absolute;
  z-index: 2;
  top: -4px;
  left: -1px;
  width: 12px;
}
