@import '@matillion/component-library/dist/assets/styles/colours';

.BrowserTree {
  // None of this is nice, but we don't have control over the Tree component and it's classes
  [role='tree'] {
    padding: 0 8px;
    overflow-x: hidden;
    overflow-y: auto;

    /* stylelint-disable-next-line selector-max-compound-selectors */
    [role='treeitem'] > div {
      margin-top: 0;
      padding: 1px 0;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      > div {
        width: 100%;
        height: 24px;
      }
    }
  }
}
