.PipelineRadioCard {
  &__PlayButton {
    padding: 0;
  }
}

.Modal__Heading {
  width: 100%;
  margin: 0 auto 24px;
  text-align: center;
}

.Modal__Title {
  font-size: 36px;
  line-height: 1em;
}
