.Toolbox {
  li {
    align-self: center;

    p {
      padding-right: 1rem;
    }
  }

  &__JobIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
  }
}
