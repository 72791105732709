@use 'sass:color';
@use '@matillion/component-library/dist/assets/styles/colours' as colours;
@use '../../styles/designTokens' as tokens;

.ComponentParameters {
  &__Row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: stretch;
    min-height: 32px;
  }

  &__Cell {
    flex: 0 0 50%;
    width: 50%;
    padding-top: 7px;
    padding-left: 24px;

    &--Name {
      flex-basis: 40%;
      width: 40%;
    }

    &--Value {
      flex-basis: 60%;
      width: 60%;
    }

    &--Message {
      flex-basis: 100%;
      width: 100%;
    }
  }

  &__HeaderRow {
    background-color: colours.$misty;
  }

  &__ValueHeaderCell {
    // this cell needs a specific left position to align with the editors underneath
    padding-left: 7px;
  }
}

.ComponentParameter {
  border-bottom: 1px solid colours.$cloudy;

  &:hover {
    background-color: tokens.$foggy;
  }

  &__Name {
    overflow: hidden;
    white-space: nowrap;
  }

  &__Value {
    padding: 0;
  }

  &__Message {
    padding-top: 2px;
    padding-bottom: 4px;
  }
}

.ComponentParameter--Error {
  background: color.change(colours.$warm-breeze, $alpha: 0.2);
  box-shadow: inset 2px 0 0 colours.$fire-red;

  &:hover {
    background: color.change(colours.$warm-breeze, $alpha: 0.5);
  }
}
