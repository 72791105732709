@import '@matillion/component-library/dist/assets/styles/colours';

$field-margin-bottom: 24px;
$radio-field-margin-bottom: 16px;
$sidebar-content-padding: 40px;
$radio-field-margin-bottom: 16px;

.Form {
  display: flex;
  flex-direction: column;
  min-height: 0;

  &__Content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-top: -6px;
    margin-left: -$sidebar-content-padding;
    padding-top: 6px;
    padding-left: $sidebar-content-padding;
    overflow: auto;
  }

  &__Description {
    resize: none;
  }

  &__ScopeFieldset {
    border: 0;
  }

  &__CustomLabel {
    margin-bottom: 8px;
  }

  &__Legend {
    margin-bottom: 24px;
  }

  &__ProjectOverrideField,
  &__FieldList > label {
    display: grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;
  }
}

.FieldError {
  display: flex;
  align-items: center;
  margin-top: calc(10px - $field-margin-bottom);
  margin-bottom: $field-margin-bottom;

  &__Icon {
    margin-right: 12px;
  }

  &__Text {
    color: $fire-red;
  }
}

.Field {
  margin-bottom: $field-margin-bottom;

  &--Radio [class*='SupportText'] {
    padding-left: 29px;
  }
}

.RadioField {
  margin-bottom: $radio-field-margin-bottom;
}
