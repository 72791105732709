.Folder {
  display: flex;
  align-items: center;

  &__Icon {
    width: 16px;
    height: 20px;
    margin-right: 6px;
  }
}
