@use '@matillion/component-library';
@import 'reactflow/dist/base.css';

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.Layout-module__Layout {
  overflow: hidden;
}

// this element is the sibling of the header, so we don't want it to be 100% tall
.Layout-module__Layout__Frame {
  min-height: 0;
}

.react-flow__node-note {
  z-index: -1 !important; // notes should sit under everything
}

.react-flow__resize-control.handle {
  padding: 4px;
  border: 0;
  background: 0;
}

.react-flow__resize-control.line {
  padding: 4px;
  border: 0;
}
