.EtlNoteInfoTooltipContent {
  &__Heading {
    margin-bottom: 20px;
  }

  &__Bold {
    font-weight: bold;
  }

  &__Italic {
    font-style: italic;
  }
}
