.Title {
  margin-top: -25px;
  margin-bottom: 10px;
}

.Alert {
  margin: 15px 0;
}

.ConsentCheckbox {
  margin: 15px 0;
}

.Footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
  padding-top: 32px;

  .Button {
    width: 100px;
  }
}
