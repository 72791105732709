@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';

.Column {
  flex-grow: 1;
  width: 320px;

  @media screen and (max-width: 1200px) {
    width: 276px;
  }

  &__SearchBar {
    @include bcs();
    height: 32px;
    border-color: $cloudy;
  }

  &__TableList {
    height: 500px;
    border: 1px solid $cloudy;
    border-top: 0;

    * {
      user-select: none;
    }

    &--empty {
      border: 1px dashed $cloudy;
      border-top: 0;
    }
  }

  &__ListHeader {
    display: flex;
    margin-bottom: 8px;

    &Description {
      margin-right: 4px;
      font-weight: 700;
    }
  }

  &__ListHeaderNumber {
    color: $electric-green;
  }
}
