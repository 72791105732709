@import '@matillion/component-library/dist/assets/styles/colours';

@mixin text {
  color: $black;
}

.Text {
  @include text;
  padding: 10px;
}

.TextWithInfo {
  @include text;
  padding: 10px 10px 0;
}

.Info {
  padding: 0 10px 6px;
  color: #2a82dd; // Matches the colour of the info icon
}

.Wrapper {
  &:hover {
    background-color: $misty;
    cursor: pointer;
  }
}

.Disabled {
  opacity: 0.3;
  pointer-events: none;
}
