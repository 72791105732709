@import '@matillion/component-library/dist/assets/styles/colours';

.SearchBar__Input {
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid transparent;
  border-left: 0;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  }

  &:hover:not([disabled]) {
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $cloudy;
    border-left: 0;
  }

  &:focus {
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $cloudy;
    border-left: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0) inset;
  }
}

.SearchBar__Toggle {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
