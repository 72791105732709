@import '@matillion/component-library/dist/assets/styles/colours';

.ModalTitle {
  margin-top: -32px;

  &__Description {
    padding-top: 4px;
    color: $hurricane;
  }
}
