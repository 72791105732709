@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.ComponentParameterMessage {
  display: flex;
  align-items: center;

  &__IconWrapper {
    position: relative;
    flex-shrink: 0;
    align-self: flex-start;

    margin-right: 16px;
  }

  &__Message {
    word-break: break-word;
  }
}

.ComponentParameterMessage--Error {
  color: colours.$fire-red;
}

.IconWrapper {
  width: 20px;
  height: 2em;

  &__Icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Icon {
  width: 20px;
  height: 20px;
}

.Icon--Error {
  background: url('../../../../assets/failed-small.svg') center no-repeat;
}

.Icon--Warning {
  background: url('../../../../assets/warning-small.svg') center no-repeat;
}
