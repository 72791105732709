@import '@matillion/component-library/dist/assets/styles/colours';

.TaskHistoryPanel {
  position: relative;
  height: 100%;

  &__PartialError {
    // same styled Alert as the PropertiesPanel
    width: 100%;
    margin: 0;
    padding: 8px 0 8px 12px;
    border: 0;
    background-color: $warm-breeze;
  }

  &__Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

// display the empty message absolutely, so the message appears inline
// with the message in the panel next to it
.EmptyTaskHistoryContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
