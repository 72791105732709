.Search {
  margin-bottom: 10px;
  padding: 0 20px;

  input {
    height: 34px;
  }
}

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
