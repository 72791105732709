$foggy: #f8f8f8;

// Canvas
$jobs-tab-height: 40px;

// Sidebar
$section-header-height: 34px;

// Sample panel
$sample-panel-header-height: 40px;
