@import '@matillion/component-library/dist/assets/styles/colours';

.Autocomplete {
  width: 100%;
  height: 32px;
  padding-left: 3px;
  border: 2px solid transparent;

  &:focus,
  &:hover,
  &:hover:not([disabled]) {
    border: 2px solid $black;
    background-color: $white;
    box-shadow: none;
  }
}

.Autocomplete__Container {
  width: 100%;
}

.FreeText {
  width: 100%;
}

.DataGrid_Override {
  [role='rowgroup'] {
    display: inline-block;
    min-width: 100%;

    label:first-of-type {
      margin-top: 2px;
    }

    [role='cell']:first-of-type {
      max-width: 10rem;
    }

    [role='columnheader']:first-of-type {
      max-width: 10rem;
    }
  }

  ul {
    z-index: 1;
    border: 0;

    li {
      padding: 5px 15px;
    }
  }
}
