.Modal {
  border-radius: 16px;
}

.Text {
  margin-bottom: 15px;
}

.Title {
  margin-bottom: 25px;
  text-align: center;
}

.Icon {
  display: block;
  margin: 0 auto 10px;
}

.Buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8px;
  padding-top: 32px;
}
