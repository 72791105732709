@import '@matillion/component-library/dist/assets/styles/colours';

.ButtonContainer {
  display: flex;

  justify-content: flex-end;
  width: 48px;
}

.Button {
  min-width: 24px;
  height: 24px;
  margin-left: 32px;
  padding: 0 3px;
  border-radius: 0;
  background: unset;
  color: #000;

  &:focus::after {
    display: none;
  }

  &:not([disabled]):hover {
    border-radius: 50%;
    background-color: $windy;
  }
}

.ButtonIcon {
  width: 24px;
  padding: 0;
  //@hack this overrides a default behavior from the TreeItem component
  circle {
    fill: #000 !important;
  }
}
