@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.AddButtonIcon {
  width: 24px;
  padding: 0;
  //@hack this overrides a default behavior from the TreeItem component
  circle {
    fill: #000 !important;
  }
}

.AddButton {
  visibility: hidden;
  align-self: flex-end;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  background: unset;
  color: colours.$black;

  &:focus::after {
    display: none;
  }

  &:not([disabled]):hover {
    border-radius: 50%;
    background-color: colours.$windy;
  }
}

.Wrapper {
  // this won't change its initial height, and since the content is scrollable it will preserve its initial height,
  // allow for its content to scroll and prevent it from increasing in height
  max-height: 0;
}

.FieldsTable {
  width: 100%;
  border-spacing: 0;
  text-align: left;

  &__Body {
    max-height: 100%;
    overflow: auto;
  }
}

.FieldsTableCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  overflow: hidden;

  &Left {
    width: 60%;
  }
}

.FieldsTableRow {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 2px 16px;
  border-bottom: 1px solid colours.$cloudy;
  cursor: pointer;

  &:hover {
    background-color: colours.$misty;

    .AddButton {
      visibility: visible;
    }
  }
}

.FieldsTableHeaders {
  display: flex;
  padding: 7px 16px;
  background-color: colours.$misty;
}

.FieldsTableHeaderLeft {
  width: 60%;
}

.FieldsSearch {
  width: 343px;
  height: 32px;
  border: 0;
  font-size: 14px;
}

.SearchRow {
  border-top: 1px solid colours.$cloudy;
  border-bottom: 1px solid colours.$cloudy;
}

.FieldType {
  margin-top: 3px;
  overflow: hidden;
  color: #6c6c6c;
  text-overflow: ellipsis;
}

.FieldName {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
