@import '@matillion/component-library/dist/assets/styles/colours';

.ParameterFailures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 0 6px 12px;
  border: 0;
  background-color: $warm-breeze;

  button:not(:disabled):hover {
    color: $black;
  }

  ul {
    margin-left: 18px;
  }

  > div > div:nth-child(2) {
    align-self: center;
  }

  button:focus::after {
    border: 0;
  }
}
