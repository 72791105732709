@import '@matillion/component-library/dist/assets/styles/colours';

.RowTypography {
  width: 100%;
}

.RowDetailTrigger {
  margin: 0 12px 0 8px;

  svg {
    width: 18px;
    height: 18px;
  }

  &:focus {
    outline: none;

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid $black;
    }
  }
}

.RightArrow {
  transform: rotate(180deg);
}

.CancelIcon {
  // the icon in MDL has tons of padding, so this counteracts that
  transform: scale(1.5);
}
