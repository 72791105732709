@import '@matillion/component-library/dist/assets/styles/colours';

.BannerContainer,
.ReadOnly {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BannerContainer {
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0 2rem;

  &__Warning {
    justify-content: space-between;
    padding-left: 0.5rem;
    border-left: 0.5rem solid $thunder-yellow;
    background-color: $sandstorm;
  }

  .ReadOnly {
    flex: 1;
    min-width: 0;
    padding-left: 1rem;

    > p {
      padding-left: 1rem;
    }
  }
}
