@import '@matillion/component-library/dist/assets/styles/colours';

.PanelHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  padding: 8px 16px;
  border-bottom: 4px solid transparent;
}

.ButtonActions {
  display: flex;
  position: relative;

  .PanelHeader {
    &__Button {
      display: inline-block;
      padding: 0;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &__Icon {
      &--isExpanded {
        transform: rotate(180deg);
      }
    }

    &__Text {
      margin-right: 12px;
      // optically aligns the Telegraf text better with the buttons
      transform: translateY(1px);
    }
  }
}

.PanelContent {
  height: 100%;
  overflow: auto;
}

.ContextIcon {
  width: 16px;
}
