@import '@matillion/component-library/dist/assets/styles/colours';

.Error {
  word-break: break-word;

  &__Ellipsis {
    letter-spacing: 2px;
  }

  &__Button {
    position: relative;
    margin-left: 4px;
    border: 0;
    background-color: unset;
    color: $fire-red;
    text-decoration: underline;
    cursor: pointer;

    &:focus {
      outline: none;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border: 2px solid $black;
        border-radius: 3rem;
      }
    }
  }
}
