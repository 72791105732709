@import '@matillion/component-library/dist/assets/styles/colours';

// hack: take TaskHistory__TasksHeader height into account
$header-height: 35px;

.TaskHistoryGrid {
  height: calc(100% - $header-height);

  [role='rowgroup']:nth-child(2) {
    overflow: auto;
  }
}

.TaskHistory {
  padding-right: 10px;

  &__TaskStatus {
    display: flex;
    align-items: center;

    &__Icon {
      width: 18px;
      height: auto;
      margin-right: 8px;
    }
  }

  &__TasksHeader {
    height: $header_height;
    padding: 8px 16px;

    &--HasData {
      border-bottom: 1px solid $cloudy;
      white-space: nowrap;
    }
  }

  &__CTA {
    flex: inherit;
    width: 48px;
    min-width: 48px;
    padding: 0;
  }
}
