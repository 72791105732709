@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';

$item-tick: url('/assets/item-tick.svg');

.AutoComplete {
  position: relative;

  &--Disabled {
    opacity: 0.3;
  }
}

.AutoComplete--Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.AutoComplete__Toggle {
  display: block;
  width: 17px;
  height: 14px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  .AutoComplete--Open & {
    transform: rotate(180deg);
  }
}

.AutoComplete__Results {
  display: none;
  position: absolute;
  z-index: 100;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid $cloudy;
  border-top: 0;
  background: $white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

  .AutoComplete--Open & {
    display: block;
  }

  &--Above-Input {
    border-top: 1px solid $cloudy;
    border-bottom-width: 0;
  }
}

.AutoComplete__List {
  max-height: 48px * 5; // 5 items tall
  margin: 0;
  padding: 0;
  overflow-y: auto;
  border-top: 1px solid transparent;
  list-style: none;
}

.AutoComplete__Item {
  @include make-input();
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: $black;
  cursor: pointer;

  mark {
    background-color: transparent;
    font-weight: 700;
  }

  &--Highlight {
    background: $misty;
  }

  &--Current {
    background: {
      position: center right 15px;
      color: $gale;
      image: $item-tick;
      repeat: no-repeat;
    }
  }

  &--Disabled {
    color: $windy;
    cursor: inherit;
  }
}

.AutoComplete__DisabledHint {
  display: flex;
  align-items: center;
  color: $black;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
}

.AutoComplete__ActionBox {
  border-top: 1px solid $cloudy;
}

.AutoCompleteAction {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border: 0;
  background-color: transparent;
  text-align: inherit;

  &__Text {
    * + & {
      margin-left: 0.75rem;
    }
  }

  &:hover {
    background-color: $misty;
    cursor: pointer;
  }

  &:hover &__Text {
    color: $plasma-green;
  }
}
