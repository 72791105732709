@import '@matillion/component-library/dist/assets/styles/colours';

%border-box-styles {
  border: 1px solid $black;
  box-shadow: inset 1px 0 $black, inset -1px 0 $black, 0 1px $black,
    0 -1px $black;
}

.Dropdown {
  height: 32px;
  box-shadow: none;

  &__Error {
    box-shadow: inset 1px 0 $fire-red, inset -1px 0 $fire-red, 0 1px $fire-red,
      0 -1px $fire-red;
  }

  &:focus,
  &:hover,
  &:hover:not([disabled]) {
    .Dropdown__Input {
      @extend %border-box-styles;
    }
  }

  [aria-expanded='true'] {
    input {
      @extend %border-box-styles;
      border-bottom: 2px solid $black !important;
    }
  }

  &__Input {
    height: 32px;
    padding-left: 5px;
    border: 1px solid transparent;
    font-size: 14px;
    text-overflow: ellipsis;

    &:focus,
    &:hover,
    &:hover:not([disabled]) {
      @extend %border-box-styles;
    }
  }
}
