@import '@matillion/component-library/dist/assets/styles/colours';

.ModalSideBar {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  height: 100%;
  margin: 0;
  padding: 40px;
  overflow: hidden;

  &__Header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  &__Title {
    margin-bottom: 8px;
  }

  &__SubText {
    color: $hurricane;
  }
}
