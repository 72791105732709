@import '@matillion/component-library/dist/assets/styles/colours';

.Stroke {
  position: absolute;
  top: -7px;
  left: -7px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $white;
}
