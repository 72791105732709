@use '@matillion/component-library/dist/assets/styles/colours' as *;

.RowError {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  &__Text {
    color: $hurricane;
  }

  &__Icon {
    // Icon and text are vertically aligned, but visually it looks a tiny bit off
    // The following visually corrects that effect and makes it look properly aligned
    position: relative;
    top: 1px;
    width: 1.5rem;
    margin-right: 1rem;
  }
}
