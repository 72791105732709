@import '@matillion/component-library/dist/assets/styles/colours';
$enter-icon: url('../../../../assets/enter-small.svg');

.Buckets {
  margin-top: 30px;

  &--Subheader {
    margin-bottom: 12px;
  }

  &--Input input {
    // gives it enough space to render the background image without being overlapped by the input's text
    padding-right: 4rem;
    overflow: auto;
    background: $enter-icon no-repeat calc(100% - 14px) 10px;
    background-size: 20px;
  }

  &--Table {
    width: 100%;
    height: 100%;
  }

  &--TableContainer {
    height: 400px;
    border: 1px solid $cloudy;
  }

  &--LoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }

  &--NameHeader,
  &--NameCell {
    flex: 3 3;
  }

  &--NameHeader {
    justify-content: space-between;
    border-bottom: 1px solid $cloudy;
  }
}
