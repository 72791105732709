.ManageVariables__Container {
  display: flex;
  flex-direction: row-reverse;
}

.ManageVariables__CreateButton {
  position: absolute;
  top: 88px;
  right: 40px;
}

.ManageVariables__Tabs {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.ManageVariables__TabList {
  display: flex;
  margin-right: auto;

  button:disabled {
    display: none;
  }
}

.ManageVariables__Content {
  height: 100%;
}

.ManageVariables__Search {
  max-width: 400px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.ManageVariables__List {
  &--NoItems {
    height: auto;
  }

  p {
    font-size: 14px;
    font-weight: normal;
  }

  button,
  button * {
    cursor: pointer;
  }
}

.ManageVariables__Row {
  height: 52px;
}

.ManageVariables__ActionsColumn {
  min-width: 68px;
  max-width: 68px;
  padding-left: 0;

  &:first-of-type p {
    position: absolute !important;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.ManageVariables__EmptyPanel,
.ManageVariables__InfoPanel {
  justify-content: flex-start;
  margin-top: 40px;
  padding-right: 120px;
  padding-left: 120px;
  text-align: center;
}

.ManageVariables__Loading {
  margin-top: 40px;
}
