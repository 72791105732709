@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.ComponentRow {
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  width: 100%;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background-color: colours.$misty;
    user-select: none;

    .DragHandle:not(.DragHandle__Invisible) {
      visibility: visible;
    }
  }

  &__Wrapper {
    display: contents;
    padding: 4px 0;
  }

  &__Icon {
    display: flex;
    margin-right: 10px;

    img {
      width: 27px;
      height: 20px;
      object-fit: contain;
    }
  }

  &__Badge {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    white-space: nowrap;
  }

  &__Tag {
    color: #6c6c6c;
  }

  &__Draggable {
    cursor: grab;
  }

  &__Unselectable {
    cursor: default;
  }
}

.DragHandle {
  visibility: hidden;
  transform: rotate(90deg);
}
