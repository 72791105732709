@use '@matillion/component-library/dist/assets/styles/colours' as *;

.ComponentParameterGroup {
  border: 0;

  &__Label {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 4px;
    padding-left: 24px;
    border-bottom: 1px solid $cloudy;
  }
}
