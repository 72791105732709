.Modal__Heading {
  width: 100%;
  margin: 0 auto 24px;
  text-align: center;
}

.Modal__Title {
  font-size: 36px;
  line-height: 1em;
}

.Modal__AlertInformation {
  margin-bottom: 24px;

  p:last-of-type {
    margin-top: 12px;
  }
}

.Modal__Content {
  > p {
    margin: 16px 0;
    font-size: 16px;
    line-height: 1.5em;
  }

  &--Input {
    min-height: 120px;
  }
}

.Modal__InlineTypography {
  display: flex;
  align-items: center;
}

.Modal__Actions {
  display: flex;
  justify-content: space-between;
  margin: 32px auto 0;
}
