@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.FieldsAndVariablesPanel {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__Tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid colours.$cloudy;
    border-bottom: 1px solid colours.$cloudy;

    &__Item {
      display: flex;
      width: 100%;
      overflow-y: scroll;
      white-space: nowrap;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &__Title {
        padding: 7px 16px;
        color: #6c6c6c;

        &--active {
          border-bottom: 2px solid colours.$black;
          color: colours.$black;
        }
      }

      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }
  }

  &__Content {
    flex-grow: 1;
  }
}

.ActiveTab {
  border-bottom: 2px solid colours.$black;
}

.ScrollButton {
  margin-right: 6px;
  margin-left: 6px;
}

.ScrollLeft {
  transform: rotate(90deg);
}

.ScrollRight {
  transform: rotate(-90deg);
}
