@import '@matillion/component-library/dist/assets/styles/colours';

.IconButton {
  position: relative;

  margin-bottom: 8px;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 0;
  }

  &:hover {
    .IconButton__Tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &__Tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 150;
    bottom: calc(100% + 6px);
    left: 50%;
    width: 120px;
    margin-left: -60px;
    padding: 8px 16px;
    transition-property: opacity;
    transition-duration: 0.75s;
    transition-delay: 0.5s;
    border: 1px solid $black;
    border-radius: 6px;

    opacity: 0;
    background-color: $black;
    color: $white;

    text-align: center;

    user-select: none;
  }

  &--disabled {
    cursor: initial;

    path {
      stroke: $windy;
      fill: none;
    }

    *:not(path) {
      fill: $misty;
    }
  }

  &:focus {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -3px;
      left: -6px;
      border: 2px solid $black;
      border-radius: 3rem;
    }
  }
}
