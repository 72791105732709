@use '@matillion/component-library/dist/assets/styles/colours' as colours;
@use 'styles/designTokens' as tokens;

%toolbar {
  position: absolute;
  z-index: 10;
  border: 1px solid colours.$cloudy;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.CanvasProvider {
  display: flex;
  position: relative;
  width: 100%;
  // takes into account the space taken by JobTabs
  height: calc(100% - tokens.$jobs-tab-height);
  min-height: 200px;
}

.Canvas {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgb(238, 238, 238);

  &__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__ZoomToolbar {
    @extend %toolbar;
    right: 24px;
    bottom: 24px;
  }

  &__Toolbox {
    @extend %toolbar;
    top: 24px;
    right: 24px;
  }
}

.Overlay {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  color: colours.$hurricane;
  text-align: center;

  &__Icon {
    margin-bottom: 8px;
  }

  &__Text {
    max-width: 400px;
  }
}
