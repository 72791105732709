@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';
@import '../../EtlNoteThemes.module';

.EtlNoteThemePicker {
  display: flex;
  // positioned relative to the beginning of the toolbar
  position: absolute;
  bottom: 50px;
  left: 57px;
  flex-direction: column;

  > div {
    display: flex;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__Icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border: 1px solid $windy;
    cursor: pointer;

    &:hover,
    &--selected {
      transform: scale(1.15);
    }

    &:last-of-type {
      margin-right: 0;
    }

    @each $name, $colour in $note-colour {
      &--#{$name} {
        background: $colour;
      }
    }
  }
}
