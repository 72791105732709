@import '@matillion/component-library/dist/assets/styles/colours';

.ComponentBrowser {
  &__Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

.PanelSearchBarContainer {
  position: relative;
  left: 0;
  transition: 1s;
  background: $white;

  &--isSearchBarVisible {
    position: absolute;
    left: 100%;
    transition: 1s;
  }
}

.Folder__Text {
  margin-top: 2px;
  line-height: 18px;
}

.Component__Text {
  padding-bottom: 1px;

  &--disabled {
    color: $hurricane;
  }
}

.Component__Text,
.Folder__Text {
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Component {
  display: flex;
  align-items: center;
  min-width: 0;
  padding-right: 9px;
  overflow: hidden;
  white-space: nowrap;

  &__Icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  &__DisabledLabel {
    margin-left: 12px;
    padding: 2px 4px;
    border: 1px solid $hurricane;
    color: $hurricane;
    font-size: 12px;
  }
}

.Folder {
  display: flex;
  align-items: center;
  width: 100%;

  &__Icon {
    width: 16px;
    height: 20px;
    margin-right: 6px;
  }
}

.Error {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 24px;
  text-align: center;

  &__Icon {
    width: 100%;
    margin-bottom: 16px;
  }

  &__Text {
    color: $hurricane;
  }

  &__Link {
    color: $electric-green;

    &:hover {
      text-decoration: underline;
    }
  }
}

// temporary approach until we implement drag-n-drop for schemas
.DefaultCursor {
  cursor: default;
}
