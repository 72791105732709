@import '@matillion/component-library/dist/assets/styles/colours';

.Box {
  border: 1px solid $cloudy;

  &__Title {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 8px;
    border-bottom: 1px solid $cloudy;
    background-color: $misty;
  }

  &__Content {
    height: 358px;
    padding: 12px 16px;
    overflow: auto;
  }

  &__Actions {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 0 8px;
    border-top: 1px solid $cloudy;
    background-color: $misty;
  }
}

.Leaf {
  display: flex;
  align-items: center;
  gap: 8px;
}

.DataType {
  color: $hurricane;
}

.ActionsParent {
  position: relative;
  z-index: 9999;
}

.ActionsPopover {
  padding: 24px;
}

.ActionsButton {
  padding: 0;
  color: inherit;

  &:not([disabled]):hover {
    color: inherit;
  }
}

.ActionsButtonDelete {
  padding: 0;
  color: $fire-red;

  &:not([disabled]):hover {
    color: $fire-red;
  }
}

.ActionsIcon {
  width: 16px;
  margin-right: 6px;
}

.ElementForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ElementFormButtons {
  display: flex;
  justify-content: space-between;
}
