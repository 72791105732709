.Footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
  padding-top: 32px;

  .Button {
    width: 100px;
  }
}

.Alert {
  margin: 15px 0;
}
