@import '@matillion/component-library/dist/assets/styles/colours';

@mixin chevron {
  width: 12px;

  path {
    stroke-width: 2px;
  }
}

.ChevronIcon {
  &--up {
    @include chevron;
    rotate: 180deg;
  }

  &--down {
    @include chevron;
  }

  &--secondary {
    path {
      stroke: $clr-btn-secondary-default;
    }
  }

  &--primary {
    path {
      stroke: $clr-btn-primary-default;
    }
  }
}
