.AgentStackTrace {
  margin-top: 32px;

  &__Title {
    margin-right: 8px;
  }

  // We can't currently override the styles for the button in CollapsiblePanel
  button {
    align-items: baseline !important;
    justify-content: flex-start !important;
    padding: 0 !important;
    background: transparent !important;
  }

  &__Content {
    margin-top: 16px;
    padding: 0;
    border-top: 0 !important;
    // The component library won't let me override these styles
    background: transparent !important;
  }
}
